import { promises as fs } from 'fs'
import path from 'path'

let prismicData = null

export const getPageData = async (queryData) => {
  const { uid = null, type = null, reserved = false } = queryData

  let rawdata = prismicData

  if (!prismicData) {
    const data = await fs.readFile(path.join(process.cwd(), 'prismic-data.json'))

    rawdata = data
    prismicData = data
  }

  const data = await JSON.parse(rawdata)
  let filteredData = null

  if (uid && type) {
    filteredData = data.find((doc) => doc.uid === uid && doc.type === type)
    return filteredData?.data
  }

  if (uid) {
    filteredData = data.find((doc) => doc.uid === uid)
    return reserved ? filteredData : filteredData?.data
  }

  if (type) {
    filteredData = data.filter((doc) => doc.type === type)
    return filteredData
  }

  return null
}

/**
 *
 * @param {object} args Args object containing the query data
 * @param {object} args.data  The page data pull from prismis-data.json
 * @param {string} args.key The prismic data prefix we are searching for
 * @returns {object} The found component data
 */
export const getComponentData = ({ data, key, useSlice = false }) => {
  const returnData = {}

  if (!useSlice) {
    for (const property in data) {
      // In Prismic we prefix our page's component data with the component name.
      // Ex, <PayoffLoan>'s headline is: 'payoff_loan_headline'. So the key used here would be: 'payoff_loan'
      if (property.startsWith(key)) {
        returnData[property] = data[property]
      }
    }
  } else {
    for (const property of data.body) {
      // In Prismic we use slice for dm pages and prefix the slice type with the component name.
      // Ex, <Hero>'s slice type is: 'hero'. So the key used here would be: 'hero'
      // "primary" and "items" are the predefined property name when using slice
      // "primary" for nonrepeatable data, "items" for repeatable data
      if (property.slice_type.startsWith(key)) {
        returnData.primary = property.primary
        returnData.items = property.items
      }
    }
  }
  return returnData
}

export default {
  getPageData,
  getComponentData
}
